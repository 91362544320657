import { AcIsObjectEqual } from '@helpers/ac-is-object-equal.helper'
import { removeItemFromArray } from '@helpers/ac-remove-from-array'
import clsx from 'clsx'
import { useEffect, useMemo, useState } from 'react'
import { AcInputBase } from '../ac-input-base/ac-input-base'
import { AcButton, AcTypography } from '../index.core.component'
import styles from './ac-select-chips.module.scss'

interface IAcSelectChipProps {
  value?: any[]
  options: readonly any[]
  labelKey: string
  /** @default 'id' */
  valueKey?: string
  outputFormat?: 'object' | 'value'
  onChange?: (value: any) => void
  readOnly?: boolean
  allowMultiple?: boolean
  error?: boolean | string
}

export const AcSelectChips = ({
  value: defaultValue,
  options = [],
  onChange,
  labelKey,
  valueKey = 'id',
  outputFormat = 'object',
  readOnly,
  allowMultiple = true,
  error,
}: IAcSelectChipProps) => {
  // We will only allow value as output when the behaviour is a checkbox.
  // I.e.: customer_type: 'basic'
  if (!allowMultiple) {
    outputFormat = 'value'
  }
  const [value, setValue] = useState(defaultValue)

  const toggleChip = (valueChip: readonly any[]) => {
    if (!allowMultiple && value === valueChip[valueKey as any]) {
      return 
    }
    if (outputFormat === 'object') {
      setValue((value = []) => {
        let newValue = [...value]
        const exists = value.find(
          singleValue => singleValue[valueKey] === valueChip[valueKey as any]
        )
        if (exists) {
          newValue = value.filter(
            singleValue =>
              singleValue[valueKey] !== valueChip[valueKey as any]
          )
        } else {
          newValue.push(valueChip)
        }
        onChange?.(newValue)

        return newValue
      })
    } else {
      setValue((value = []) => {
        let newValue = [...value]
        if (allowMultiple) {
          if (value.includes(valueChip[valueKey as any])) {
            newValue = removeItemFromArray(newValue, valueChip[valueKey as any])
          } else {
            newValue.push(valueChip[valueKey as any])
          }
          onChange?.(newValue)
        } else {
          // Must not send it as an Array if !allowMultiple
            onChange?.(valueChip[valueKey as any])
          
        }

        return newValue
      })
    }
  }

  const renderButtonChips = useMemo(() => {
    if (!options?.length) return null

    if (readOnly) {

      if (typeof value === 'string') {
        return <AcTypography>{options.find(opt => opt.key === value)?.label}</AcTypography>
      }

      if (!value?.length) return null

      return (
        <AcTypography
          weight="semibold"
          size="sm">
          {outputFormat === 'object'
            ? value.map(({ [labelKey]: label }) => label).join(', ')
            : value
              .map(singleValue => {
                const option = options.find(
                  singleOption => singleOption[valueKey] === singleValue
                )
                return option[labelKey]
              })
              .join(', ')}
        </AcTypography>
      )
    }

    const chipElements = []
    for (const optionChip of options) {
      let isChecked = false

      if (typeof value === 'string' && !allowMultiple) {
        isChecked = value === optionChip[valueKey]
      } else {
        isChecked =
          outputFormat === 'object'
            ? value?.find(
              valueChip => valueChip[valueKey] === optionChip[valueKey]
            )
            : value?.find(valueChip => valueChip === optionChip[valueKey])
      }

      chipElements.push(
        <AcButton
          label={optionChip[labelKey]}
          color={isChecked ? 'primary' : 'secondary'}
          padding="chip"
          onClick={() => toggleChip(optionChip)}
        />
      )
    }

    return chipElements
  }, [value, options, readOnly])

  useEffect(() => {
    if (!AcIsObjectEqual(value, defaultValue)) setValue(defaultValue)
  }, [defaultValue])

  return (
    <AcInputBase
      padding={readOnly ? 14 : 0}
      error={error}
      className={clsx(styles['ac-select-chip'])}>
      {renderButtonChips}
    </AcInputBase>
  )
}
